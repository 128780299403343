import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useUserPermission from '../../../hooks/useUserPermission'
import clsx from 'clsx'
import { Button } from '../../atoms/Button'
import CheckboxField from '../../molecules/fields/CheckboxField'
import UsersFormAssignedModal from './UsersFormAssignedModal'
import { useField } from 'react-final-form'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ToastAlert from '../../molecules/ToastAlert'
import ConfirmDialog from '../../molecules/dialogs/ConfirmDialog'

const UsersFormAssigned = ({ emailEnabled, initialValues }) => {
  const { canEditCreateOrEditForms, canChangeAllowedData } = useUserPermission('user')
  const [openFormList, setOpenFormList] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showAlertText, setShowAlertText] = useState('')
  const { input: inputAllowedForm } = useField('allowed_forms')
  const { input: inputAllowedQuickSearch } = useField('allowed_quick_searches')
  const allowFormName = 'allowFormName'
  const allowedCount = inputAllowedForm.value?.length + inputAllowedQuickSearch.value?.length

  const handleClose = () => {
    setOpenFormList(false)
  }

  const handleChangeAllowed = (forms, quickSearches) => {
    setShowAlertText(
      allowedCount === 0 ? 'Formularios agregados exitosamente' : 'Formularios asignados editados exitosamente'
    )
    setShowAlert(true)
    inputAllowedForm.onChange(forms)
    inputAllowedQuickSearch.onChange(quickSearches)
    handleClose()
  }

  const handleClearAllowedForms = () => {
    inputAllowedForm.onChange([])
    inputAllowedQuickSearch.onChange([])
    setShowConfirm(false)
  }

  const hasValues = allowedCount > 0

  return (
    <>
      {canChangeAllowedData(initialValues) && (
        <>
          <h3 className={clsx('block font-bold text-base mb-1')} aria-describedby={`${allowFormName}-hint`}>
            Formularios y consultas rápidas asignadas
          </h3>
          <p className="my-4" id={`${allowFormName}-hint`}>
            Selecciona los contenidos a los que tendrá acceso de <strong>visualización o edición</strong>
          </p>
          {hasValues ? (
            <div className="flex gap-2 md:flex-row flex-col">
              <p className="p-3 border-2 border-solid border-gray-800 flex items-center flex-grow">
                {allowedCount === 1 ? '1 formulario asignado' : `${allowedCount} formularios asignados`}
              </p>
              <div className="flex gap-2 justify-end">
                <Button
                  variant="secondary"
                  size="full"
                  className="max-w-16"
                  aria-label="Editar"
                  onClick={() => setOpenFormList(true)}
                >
                  <EditIcon />
                </Button>
                <Button variant="secondary" size="xs" onClick={() => setShowConfirm(true)}>
                  <DeleteIcon className="mr-2" />
                  Limpiar
                </Button>
              </div>
            </div>
          ) : (
            <Button variant="secondary" className="md:max-w-64" size="full" onClick={() => setOpenFormList(true)}>
              Seleccionar formularios
            </Button>
          )}
        </>
      )}
      {canEditCreateOrEditForms && (
        <CheckboxField
          className="mt-2"
          name="permission.forms.create_or_edit"
          label="Puede crear y editar sus propios formularios y consultas rápidas, así como editar los que le has dado acceso."
          disabled={!emailEnabled}
        />
      )}
      {openFormList && (
        <UsersFormAssignedModal
          open
          onClose={handleClose}
          onSave={handleChangeAllowed}
          initialValues={{ form: inputAllowedForm.value, quickSearch: inputAllowedQuickSearch.value }}
        />
      )}
      <ToastAlert open={showAlert} onClose={() => setShowAlert(false)} alertText={showAlertText} type="success" />
      <ConfirmDialog
        title="¿Estas seguro de quitar todos los formularios que has seleccionado?"
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        primaryConfirmBtn
        rightButton={{
          text: 'Sí, quitarlos',
          onClick: () => handleClearAllowedForms()
        }}
      ></ConfirmDialog>
    </>
  )
}

UsersFormAssigned.propTypes = {
  emailEnabled: PropTypes.bool,
  initialValues: PropTypes.object
}

export default UsersFormAssigned
