import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { clearMutator, markForDestroyMutator } from '../../../utils/formMutators'
import pageMutators from '../../../utils/pageMutators'
import { focusOnFirstError } from '../../../utils/decorators'
import FormsFormSetup from './FormsFormSetup'
import SaveButton from '../../molecules/buttons/SaveButton'
import { Form, Field } from 'react-final-form'
import { getConfig, setupSerializedFormData } from '../../../configs/formsConfig'
import BeforeunloadForm from './BeforeunloadForm'
import useSearchParams from '../../../hooks/useSearchParams'

const FormsForm = ({
  config: customConfig = {},
  initialValues,
  mutateForm,
  buttonMessage = 'Guardar y continuar',
  noPristineToDisable = false
}) => {
  const config = getConfig(customConfig)
  const [uploadProgress, setUploadProgress] = useState(null)
  const searchParams = useSearchParams()
  const acceptedCivilRegistrationConditions = searchParams.get('accepted')
  const approvedTupa = searchParams.get('approvedTupa')

  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    if (mutateForm) {
      await mutateForm({ values: formData, config })
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
        clear: clearMutator,
        markForDestroy: markForDestroyMutator,
        ...pageMutators
      }}
      initialValues={initialValues}
      decorators={[focusOnFirstError]}
      subscription={{ pristine: true, submitting: true, submitSucceeded: true }}
    >
      {({ handleSubmit, submitting, pristine, submitSucceeded }) => (
        <form onSubmit={handleSubmit}>
          <BeforeunloadForm pristine={pristine} submitting={submitting} submitSucceeded={submitSucceeded} />
          <FormsFormSetup config={config} />
          <div className="mb-10 md:mb-12">
            <SaveButton
              label={buttonMessage}
              uploadProgress={uploadProgress}
              disabled={(!noPristineToDisable && pristine) || submitting}
            />
          </div>
          <Field
            component="input"
            className="hidden"
            name="civil_registration_conditions.accepted"
            initialValue={acceptedCivilRegistrationConditions}
          />
          <Field
            component="input"
            className="hidden"
            name="civil_registration_conditions.approvedTupa"
            initialValue={approvedTupa}
          />
        </form>
      )}
    </Form>
  )
}
export default FormsForm

FormsForm.propTypes = {
  buttonMessage: PropTypes.string,
  config: PropTypes.object,
  initialValues: PropTypes.object,
  mutateForm: PropTypes.func.isRequired,
  noPristineToDisable: PropTypes.bool
}
