import PropTypes from 'prop-types'
import React from 'react'
import { HorizontalLinearStepper } from '../../molecules/HorizontalLinearStepper'
import Alert from '../../molecules/Alert'
import useSearchParams from '../../../hooks/useSearchParams'
import { steps } from '../../../configs/formsConfig'

const HeaderCreateForm = ({ headerParams }) => {
  const subTitle = `${headerParams.activeStep + 1}. ${steps[headerParams.activeStep]}`
  const searchParams = useSearchParams()
  const showSuccessCondition = searchParams.get('accepted') === 'true' && searchParams.get('approvedTupa')

  return (
    <div className="mb-6 mx-4 md:mx-0">
      {showSuccessCondition && (
        <Alert type="success" className={'mb-4'}>
          La información se envió con éxito, puedes continuar con la creación de tu formulario.
        </Alert>
      )}
      <h1 aria-describedby="page-description" className="title mb-4">
        Crear un formulario digital
      </h1>
      <p className="mb-10" id="page-description">
        Crea un nuevo formulario digital para un trámite o servicio de tu institución.
      </p>
      <div className="m-auto max-w-xl mb-20">
        <HorizontalLinearStepper steps={steps} activeStep={headerParams.activeStep} />
      </div>
      <h2 className="text-3xl font-bold mb-2">{subTitle}</h2>
      <p className="mb-2" id="page-hint">
        {headerParams.hint}
      </p>
    </div>
  )
}

export default HeaderCreateForm

HeaderCreateForm.propTypes = {
  headerParams: PropTypes.exact({
    activeStep: PropTypes.number,
    hint: PropTypes.string
  }).isRequired
}
