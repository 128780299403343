import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Form as FinalForm } from 'react-final-form'
import StepsFields from '../forms/StepsFields'
import arrayMutators from 'final-form-arrays'
import { clearMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import Instructions from '../../molecules/Instructions'
import { focusOnFirstError } from '../../../utils/decorators'
import Value from '../../molecules/Value'
import SaveButton from '../../molecules/buttons/SaveButton'
import { setupSerializedFormData } from '../../../configs/formsConfig'
import pageMutators from '../../../utils/pageMutators'

const instructions = [
  'Define cada pregunta para la plantilla: qué tipo de pregunta son, sus contenidos y si es que son obligatorias u opcionales.',
  'Para las preguntas con alternativas, define si podrán ser editadas o no por el funcionario público.',
  'Puedes ordenar y fijar las preguntas en el orden que mejor creas conveniente para tu plantilla.'
]

const Questions = ({ template, mutateForm }) => {
  const [uploadProgress, setUploadProgress] = useState(null)
  useEffect(() => {
    let timer
    if (uploadProgress === 100) timer = setTimeout(() => setUploadProgress(null), 1000)
    return () => timer && clearTimeout(timer)
  }, [uploadProgress])

  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    const config = {
      onUploadProgress: progressEvent =>
        setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    }
    try {
      await mutateForm({ values: formData, config })
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <>
      <FinalForm
        initialValues={template}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
          swap: customSwapMutator,
          clear: clearMutator,
          markForDestroy: markForDestroyMutator,
          ...pageMutators
        }}
        decorators={[focusOnFirstError]}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="bg-neutral-100 px-4 py-8 md:px-12 md:py-6 mb-0">
              <div className="text-2xl font-bold">Preguntas de la plantilla</div>
              <Instructions list={instructions} />
              <div className="border-b border-t border-neutral-400 py-6 mb-6">
                <StepsFields editableAlternatives />
              </div>
              <Value name="privacy_policy_example_type">
                {({ value: privacyPolicyExampleType }) => (
                  <SaveButton
                    uploadProgress={privacyPolicyExampleType === 'modified' ? uploadProgress : null}
                    disabled={submitting}
                  />
                )}
              </Value>
            </div>
          </form>
        )}
      </FinalForm>
    </>
  )
}
export default Questions

Questions.propTypes = {
  mutateForm: PropTypes.func.isRequired,
  template: PropTypes.object
}
