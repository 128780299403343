import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import AnnouncementEdit from '../../components/pages/admin/announcements/AnnouncementEdit'
import AnnouncementNew from '../../components/pages/admin/announcements/AnnouncementNew'
import useUserPermission from '../useUserPermission'

const useAnnouncementsRoute = () => {
  const { canManageAnnouncements } = useUserPermission('announcement')
  return [
    { path: '/avisos', component: ResourcesTab, hasPermission: canManageAnnouncements },
    { path: '/avisos/crear', component: AnnouncementNew, hasPermission: canManageAnnouncements },
    { path: '/avisos/:id/editar', component: AnnouncementEdit, hasPermission: canManageAnnouncements }
  ]
}
export default useAnnouncementsRoute
