import React, { useMemo } from 'react'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import Form from '../../../organisms/templates/Form'
import axios from 'axios'
import { FormSteps } from '../../../../configs/templatesConfig'
import FormsHeader from '../../../molecules/FormsHeader'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

const createForm = ({ values, config }) => axios.post('/admin/v1/templates.json', values, config)

const TemplatesNew = () => {
  const history = useHistory()
  const template = useMemo(() => ({ steps_attributes: [] }), [])
  const { mutate } = useMutation(createForm, {
    onSuccess: ({ data }) => history.push(`/plantillas/${data.id}/preguntas`)
  })
  return (
    <>
      <PageNavigation />
      <Container className="pt-12">
        <FormsHeader
          title="Crear una plantilla de formulario"
          hint="Crear una plantilla para las instituciones públicas usuarias de Facilita."
          steps={FormSteps}
          activeStep={FormSteps.CREATION}
        />
        <Form template={template} mutateForm={mutate} />
      </Container>
    </>
  )
}
export default TemplatesNew
