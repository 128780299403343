import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin } = roles

const Announcement = () => {
  const hasRolePermissions = useHasRolePermissions()

  const canManageAnnouncements = hasRolePermissions([admin])

  return {
    canManageAnnouncements
  }
}

export default Announcement
