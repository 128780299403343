import { FormControl } from '@material-ui/core'
import React from 'react'
import { dataToOptions } from '../../../utils/helpers'
import RolesFilter from '../filters/RolesFilter'
import CheckboxField from '../../molecules/fields/CheckboxField'
import { frequencyOptions } from '../../../configs/announcementConfig'
import useInstitutionType from '../../../hooks/useInstitutionType'
import SelectField from '../../molecules/fields/SelectField'
import DateCalendarField from '../../molecules/fields/DateCalendarField'

const AnnouncementFilter = () => {
  const { getInstitutionTypes } = useInstitutionType()
  const { data: institutionTypes, status } = getInstitutionTypes()
  const institutionTypesWithAll = [{ value: 'all', label: 'Todos' }, ...dataToOptions(institutionTypes)]

  if (status !== 'success') return null
  return (
    <div className="flex gap-10 md:flex-row w-full flex-col py-6 px-0.5">
      <RolesFilter label="Destinatarios" />
      <FormControl component="fieldset">
        <label htmlFor="sort-input" className="font-bold mr-4">
          Frecuencia
        </label>
        {frequencyOptions.map(option => (
          <CheckboxField key={option.id} name="frequency" label={option.name} value={option.id} margin="none" />
        ))}
      </FormControl>
      <FormControl>
        <SelectField
          className="min-w-60"
          label="Tipo de entidad"
          name="institution_type_id"
          options={institutionTypesWithAll}
        />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:mt-0' }}>
        <legend className="text-base font-bold text-gray-800 mb-2">Fecha</legend>
        <DateCalendarField name="date" size="2xl" />
      </FormControl>
    </div>
  )
}

export default AnnouncementFilter
