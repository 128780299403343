import PropTypes from 'prop-types'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from './IconButton'

const DeleteButton = ({ onClick, disabled }) => {
  return (
    <IconButton text="Eliminar" onClick={onClick} disabled={disabled}>
      <DeleteIcon />
    </IconButton>
  )
}

export default DeleteButton

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
