import React from 'react'
import Container from '../../../atoms/Container'
import { useHistory, useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import FormsForm from '../../../organisms/forms/FormsForm'
import FormsHeader from '../../../molecules/FormsHeader'
import { steps } from '../../../../configs/formsConfig'
import useForm from '../../../../hooks/useForm'

const FormsEdit = () => {
  const history = useHistory()
  const { id: formId } = useParams()
  const { getForm, updateForm, invalidateForm } = useForm(formId)
  const { data: form, status } = getForm()
  const stepsLabeled = steps.map(step => ({ label: step }))

  const handleFormUpdate = async ({ values, config }) => {
    try {
      updateForm(
        { values, config },
        {
          onSuccess: () => {
            invalidateForm()
            history.push(`/formularios/${formId}/preguntas?action=edit`)
          }
        }
      )
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  if (status !== 'success') return null
  return (
    <>
      <PageNavigation />
      <Container className="pt-12">
        <FormsHeader
          title={`Editando formulario: ${form.name}`}
          hint="Edita el formulario digital"
          description="Las ediciones realizadas no alterán las respuestas anteriores.
          Si los cambios son drásticos considera crear otro formulario y despublicar este."
          activeStep={stepsLabeled[1]}
          steps={stepsLabeled}
        />
        <FormsForm
          initialValues={form}
          config={form.config}
          mutateForm={handleFormUpdate}
          buttonMessage="Guardar"
          noPristineToDisable
        />
      </Container>
    </>
  )
}

export default FormsEdit
