import React from 'react'
import Container from '../../../atoms/Container'
import { headerInfoEdit } from '../../../../configs/announcementConfig'
import AnnouncementForm from '../../../organisms/announcements/AnnouncementForm'
import { useHistory, useParams } from 'react-router-dom'
import useAnnouncement from '../../../../hooks/useAnnouncement'
import { isSuccessful } from '../../../../hooks/useQuery'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const AnnouncementEdit = () => {
  const { updateAnnouncement, getAnnouncement } = useAnnouncement()
  const { id } = useParams()
  const { data: announcementData, status } = getAnnouncement(id)
  const history = useHistory()

  const handleSave = async values => {
    try {
      await updateAnnouncement(values, {
        onSuccess: () => {
          history.push('/avisos?action=announcement_edited')
        }
      })
    } catch (error) {
      alert(error)
    }
  }

  if (!isSuccessful(status)) return null
  return (
    <>
      <PageNavigation />
      <Container className="py-16">
        <AnnouncementForm headerInfo={headerInfoEdit} initialValues={announcementData} onSubmit={handleSave} />
      </Container>
    </>
  )
}
export default AnnouncementEdit
