import React, { useState, useEffect } from 'react'
import Container from '../../atoms/Container'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../../molecules/Auth'
import { getTabsTree, flatTabsTreeAndFilterVisible } from '../../../configs/tabsConfig'
import Alert from '../../molecules/Alert'
import useUserPermission from '../../../hooks/useUserPermission'
import useUser from '../../../hooks/useUser'
import MenuList from './MenuList'
import AnnouncementUser from '../announcements/AnnouncementUser'

export const ResourcesTab = () => {
  const location = useLocation()
  const pathname = location.pathname
  const { user } = useAuth()
  const { canSeeBranchOfficeAssignedNotification } = useUserPermission('user')
  const [showNotify, setShowNotify] = useState(canSeeBranchOfficeAssignedNotification)
  const { updateUser } = useUser()

  const menuPermissions = useUserPermission('menu')
  const tabsTree = getTabsTree(menuPermissions)
  const flattedTabs = flatTabsTreeAndFilterVisible(tabsTree)

  const getMainComponentIndex = flattedTabs.findIndex(r => r.path === pathname)
  const elementTab = flattedTabs[getMainComponentIndex].component

  const handleChangeTab = async ev => {
    if (ev.target.getAttribute('data-component-id') === 'menu-tab') {
      if (canSeeBranchOfficeAssignedNotification) {
        setShowNotify(false)
      }
    }
  }

  useEffect(() => {
    setShowNotify(canSeeBranchOfficeAssignedNotification)
    if (canSeeBranchOfficeAssignedNotification) {
      updateUser({ id: user.id, branch_office_assign_notified: true })
    }
  }, [canSeeBranchOfficeAssignedNotification])

  return (
    <div>
      <MenuList onChangeMenu={handleChangeTab} />
      <AnnouncementUser />
      {showNotify && (
        <Container>
          <Alert type="info" className="mt-10">
            Has sido asignado a la{' '}
            {user.branch_office_name.toLowerCase().startsWith('sede')
              ? user.branch_office_name
              : `sede ${user.branch_office_name}`}
            .
            <span className="font-normal block">
              Ahora podrás gestionar tus servicios dentro de la sede que te corresponde.
            </span>
          </Alert>
        </Container>
      )}
      <Container>{elementTab}</Container>
    </div>
  )
}
