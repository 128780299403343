import React from 'react'
import PageHeading from '../../../molecules/PageHeading'
import Alert from '../../../molecules/Alert'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import AnnouncementsBody from '../../../organisms/announcements/AnnouncementsBody'

export const Announcements = () => {
  const { search } = useLocation()
  const urlSearchParams = queryString.parse(search)
  return (
    <div>
      {urlSearchParams.action === 'announcement_created' && (
        <Alert type="success" className="mt-10">
          Aviso creado con éxito.
        </Alert>
      )}

      <PageHeading
        title="Avisos"
        pageDescription="Envía avisos a los funcionarios usuarios de Facilita."
        button={{
          link: '/avisos/crear',
          name: 'Enviar nuevo aviso'
        }}
      />
      <AnnouncementsBody />
    </div>
  )
}
