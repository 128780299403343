import PropTypes from 'prop-types'
import React from 'react'
import { Info, Close } from '@material-ui/icons'
import clsx from 'clsx'
import { IconButton } from '@material-ui/core'
import Container from '../atoms/Container'

const Announcement = ({ url, text, onClose, onShowMore }) => {
  return (
    <div
      className={clsx('p-1 flex justify-between flex-row rounded relative self-start items-center bg-green-700')}
      role="alert"
      aria-live="polite"
    >
      <Container className="flex items-center justify-center">
        <i aria-hidden></i>
        <div className="text-white flex items-center gap-4 mr-4">
          <Info />
          <div className="hint-html-injected break-words" dangerouslySetInnerHTML={{ __html: text }} />
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-white underline text-sm min-w-fit"
            onClick={onShowMore}
          >
            VER MÁS
          </a>
        </div>
        <IconButton onClick={onClose} aria-label="Cerrar" size="small">
          <Close className="text-white" fontSize="small" />
        </IconButton>
      </Container>
    </div>
  )
}

export default Announcement

Announcement.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  onClose: PropTypes.func,
  onShowMore: PropTypes.func
}
