import axios from 'axios'
import useMutation from './useMutation'

export const useFileUploadMutation = (urlBase, id) => {
  const postRecord = (values, config) => axios.post(`${urlBase}.json`, values, config).then(res => res.data)
  const putRecord = (values, config) => axios.put(`${urlBase}/${id}.json`, values, config).then(res => res.data)

  const { mutate } = useMutation(args => {
    return id ? putRecord(args.values, args.config) : postRecord(args.values, args.config)
  })

  return { mutate }
}
