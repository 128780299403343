import React, { Fragment } from 'react'
import AnnouncementTable from './AnnouncementTable'
import useFilters from '../../../hooks/useFilters'
import { BoxTable } from '../../molecules/BoxTable'
import { sortOptions } from '../../../configs/notificationsConfig'
import { urlBase } from '../../../hooks/useAnnouncement'
import { urlToJson } from '../../../utils/helpers'
import AnnouncementFilter from './AnnouncementFilter'

const AnnouncementsBody = () => {
  const {
    onFilter,
    initialFilters,
    results: announcements
  } = useFilters(urlToJson(urlBase), { notification_sort: 'created_at desc' })

  return (
    <Fragment>
      <BoxTable
        initialFilters={initialFilters}
        filters={<AnnouncementFilter />}
        onFilter={onFilter}
        values={announcements}
        paginationLabel="page"
        searchFieldName="search"
        sortByName="sort"
        sortOptions={sortOptions}
        searchPlaceholder="Buscar por contenido"
        displayTotal
        displayHeader={false}
        accordionHasBackground={false}
      >
        <AnnouncementTable announcements={announcements.data} />
      </BoxTable>
    </Fragment>
  )
}
export default AnnouncementsBody
