import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import IconButtonMUI from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import Tooltip from '../../atoms/Tooltip'
import clsx from 'clsx'

const classByColorType = {
  'filled-primary': 'text-white bg-blue-700 hover:bg-blue-800'
}

const IconButton = ({
  text,
  tooltipText,
  children,
  component = 'button',
  asTooltip = false,
  onClick,
  tooltipProps,
  size,
  color = 'primary',
  className,
  placement = 'bottom',
  disabled,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const handleClick = () => {
    onClick && onClick()
    asTooltip && tooltipRef.current.openTooltip()
  }
  return (
    <Tooltip title={tooltipText || text} ref={tooltipRef} {...tooltipProps} placement={placement}>
      <IconButtonMUI
        className={clsx(classByColorType[color], className)}
        size={size}
        type="button"
        color={color}
        aria-label={text}
        component={component === 'Link' ? Link : component}
        onClick={handleClick}
        disabled={disabled}
        {...props}
      >
        {children}
      </IconButtonMUI>
    </Tooltip>
  )
}

export default IconButton

IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  component: PropTypes.oneOf(['a', 'button', 'Link']),
  asTooltip: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipText: PropTypes.node,
  tooltipProps: PropTypes.object,
  disabled: PropTypes.bool
}
