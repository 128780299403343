import { urlToJson } from '../utils/helpers'
import useCrud from './useCrud'
import useInvalidateQueries from './useInvalidateQueries'

export const urlBase = '/admin/v1/announcements'
const urlBaseExecution = '/admin/v1/execution_announcements'

const useAnnouncement = () => {
  const { get: getAnnouncements, update, findById, create: createAnnouncement, destroy } = useCrud(urlBase)
  const invalidateQueries = useInvalidateQueries()

  const getAnnouncement = id => findById(['announcement', id])

  const { mutate: updateAnnouncement } = update()

  const { mutate: destroyAnnouncement } = destroy()

  const invalidateAnnouncements = () => invalidateQueries([urlToJson(urlBase)])

  const { get: getUserAnnouncement } = useCrud(`${urlBaseExecution}/to_current_user`)
  const { update: updateCrudAnnouncement } = useCrud(`${urlBaseExecution}`)
  const { mutate: updateUserAnnouncement } = updateCrudAnnouncement()

  return {
    getAnnouncements,
    getAnnouncement,
    updateAnnouncement,
    createAnnouncement,
    destroyAnnouncement,
    invalidateAnnouncements,
    getUserAnnouncement,
    updateUserAnnouncement
  }
}

export default useAnnouncement
