import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import HighlightOff from '@material-ui/icons/HighlightOff'
import { getRolDetails } from '../../../configs/usersConfig'
import EditButton from '../../molecules/buttons/EditButton'
import Table from '../../atoms/table/Table'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import { getFrequencyOptionName } from '../../../configs/announcementConfig'
import useAnnouncement from '../../../hooks/useAnnouncement'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import { parsedDate, parsedDateEnd } from '../../../utils/helpers'
import IconButton from '../../molecules/buttons/IconButton'

const AnnouncementTable = ({ announcements = [] }) => {
  const { updateAnnouncement, destroyAnnouncement, invalidateAnnouncements } = useAnnouncement()
  const today = new Date()

  const handleCancel = async announcement => {
    if (!announcement.canceled && window.confirm('¿Estás seguro que deseas cancelar el aviso?')) {
      updateAnnouncement(
        { id: announcement.id, canceled: true },
        {
          onSuccess: () => invalidateAnnouncements()
        }
      )
    }
  }
  const handleDelete = async announcement => {
    if (window.confirm('¿Estás seguro que deseas eliminar el aviso?')) {
      destroyAnnouncement(announcement.id, {
        onSuccess: () => invalidateAnnouncements(),
        onError: error => window.alert(error.response?.data?.message || 'Hubo un error al eliminar el aviso')
      })
    }
  }

  const showActions = announcement => {
    const futureAnnouncement = parsedDate(announcement.date_from) > today
    const activeAnnouncement = parsedDate(announcement.date_from) < today && parsedDateEnd(announcement.date_to) > today
    return (
      <Td className="w-1/2 p-0">
        <EditButton to={`/avisos/${announcement.id}/editar`} disabled={!futureAnnouncement} />
        <DeleteButton onClick={() => handleDelete(announcement)} disabled={!futureAnnouncement} />
        <IconButton
          text={announcement.canceled ? 'Cancelado' : 'Cancelar'}
          disabled={!activeAnnouncement || announcement.canceled}
          onClick={() => handleCancel(announcement)}
        >
          <HighlightOff />
        </IconButton>
      </Td>
    )
  }

  return (
    <div className="overflow-x-scroll md:overflow-x-auto">
      <Table
        className="table-fixed md:w-full w-800"
        values={announcements}
        head={
          <Tr body={false}>
            <Th className="w-4">ID</Th>
            <Th className="w-3/12">Contenido</Th>
            <Th className="w-30">Url</Th>
            <Th className="w-2/12">Destinatarios</Th>
            <Th className="w-2/12">Periodo de publicación</Th>
            <Th className="w-2/12">Frecuencia</Th>
            <Th className="w-2/12">Resultados de envío</Th>
            <Th className="w-2/12">Acciones</Th>
          </Tr>
        }
        body={announcements?.map(announcement => {
          return (
            <Tr key={announcement.id} className="align-middle">
              <Td>{announcement.id}</Td>
              <Td>
                <div
                  className="hint-html-injected break-words"
                  dangerouslySetInnerHTML={{ __html: announcement.content }}
                />
              </Td>
              <Td>
                <a href={announcement.url_destination} target="_blank" rel="noreferrer">
                  Enlace
                </a>
              </Td>
              <Td>
                {announcement.permissions?.map((option, index) => (
                  <Fragment key={index}>
                    {getRolDetails(option).name} <br />
                  </Fragment>
                ))}
              </Td>
              <Td>
                {announcement.date_from} - {announcement.date_to}
              </Td>
              <Td>{getFrequencyOptionName(announcement.frequency)}</Td>
              <Td>
                <div>
                  <p className="font-bold">
                    Total:{' '}
                    {announcement.statistics?.opened +
                      announcement.statistics?.closed +
                      announcement.statistics?.ignored}
                  </p>
                  <p>Abiertos: {announcement.statistics?.opened}</p>
                  <p>Cerrados: {announcement.statistics?.closed}</p>
                  <p>Ignorados: {announcement.statistics?.ignored}</p>
                </div>
              </Td>
              {showActions(announcement)}
            </Tr>
          )
        })}
      />
    </div>
  )
}

export default AnnouncementTable

AnnouncementTable.propTypes = {
  announcements: PropTypes.array
}
