import PropTypes from 'prop-types'
import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from './IconButton'

const EditButton = ({ component = 'Link', text = 'Editar', disabled, ...props }) => {
  return (
    <IconButton text={text} component={component} disabled={disabled} {...props}>
      <EditIcon />
    </IconButton>
  )
}

export default EditButton

EditButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  component: PropTypes.oneOf(['a', 'button', 'Link'])
}
