import React from 'react'
import Announcement from '../../molecules/Announcement'
import useAnnouncement from '../../../hooks/useAnnouncement'
import { isSuccessful } from '../../../hooks/useQuery'
import useInvalidateQueries from '../../../hooks/useInvalidateQueries'

const AnnouncementUser = () => {
  const invalidateQueries = useInvalidateQueries()
  const { getUserAnnouncement, updateUserAnnouncement } = useAnnouncement()
  const { data: announcement, status } = getUserAnnouncement('current_user_announcement')

  const handleShowMore = () => {
    updateUserAnnouncement(
      { id: announcement.id, clicked: true },
      {
        onSuccess: () => {
          invalidateQueries('current_user_announcement')
        }
      }
    )
  }

  const handleClose = () => {
    updateUserAnnouncement(
      { id: announcement.id, closed: true },
      {
        onSuccess: () => {
          invalidateQueries('current_user_announcement')
        }
      }
    )
  }

  if (!isSuccessful(status) || !announcement.content) return null

  return (
    <Announcement
      text={announcement.content}
      url={announcement.url_destination}
      onClose={handleClose}
      onShowMore={handleShowMore}
    />
  )
}

export default AnnouncementUser
