import useHasRolePermissions from '../useHasRolePermissions'
import { roles } from '../../configs/permissionsConfig'

const { admin, coordinator, branch_coordinator: branchCoordinator, collaborator } = roles

const Menu = () => {
  const hasRolePermissions = useHasRolePermissions()

  const canSeeTemplates = hasRolePermissions([admin])
  const canSeeNotifications = hasRolePermissions([admin])
  const canManageHelpCenter = hasRolePermissions([admin])
  const canSeePaymentMethods = hasRolePermissions([admin, coordinator])
  const canSeeProcedureManagements = hasRolePermissions([admin])
  const canSeeEnabledProcedureManagements = hasRolePermissions([coordinator, branchCoordinator, collaborator])
  const canSeeAreasAndUsers = hasRolePermissions([admin, coordinator, branchCoordinator])
  const canSeeBranchOffices = hasRolePermissions([admin, coordinator])
  const canSeeAllEnabledProcedureManagements = hasRolePermissions([admin])
  const canSeeInstitutionInfo = hasRolePermissions([admin])
  const canSeeTerms = hasRolePermissions([admin])
  const canSeeStatistics = hasRolePermissions([admin, coordinator, branchCoordinator, collaborator])
  const canSeeAnnouncements = hasRolePermissions([admin])

  return {
    canSeeTemplates,
    canSeeNotifications,
    canManageHelpCenter,
    canSeeProcedureManagements,
    canSeePaymentMethods,
    canSeeEnabledProcedureManagements,
    canSeeAreasAndUsers,
    canSeeTerms,
    canSeeBranchOffices,
    canSeeAllEnabledProcedureManagements,
    canSeeInstitutionInfo,
    canSeeStatistics,
    canSeeAnnouncements
  }
}

export default Menu
