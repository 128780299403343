import { ResourcesTab } from '../../components/organisms/navigation/ResourcesTab'
import TemplatesNew from '../../components/pages/admin/templates/TemplatesNew'
import TemplatesEdit from '../../components/pages/admin/templates/TemplatesEdit'
import TemplatePreview from '../../components/pages/admin/templates/TemplatePreview'
import useUserPermission from '../useUserPermission'
import TemplateEditQuestions from '../../components/pages/admin/templates/TemplateEditQuestions'
import TemplateNewQuestions from '../../components/pages/admin/templates/TemplateNewQuestions'

const useTemplatesRoute = () => {
  const { canSeeTemplates } = useUserPermission('menu')

  return [
    { path: '/plantillas', component: ResourcesTab, hasPermission: canSeeTemplates },
    { path: '/plantillas/crear', component: TemplatesNew, hasPermission: canSeeTemplates },
    { path: '/plantillas/:id/preguntas', component: TemplateNewQuestions, hasPermission: canSeeTemplates },
    { path: '/plantillas/:id/editar', component: TemplatesEdit, hasPermission: canSeeTemplates },
    { path: '/plantillas/:id/editar-preguntas', component: TemplateEditQuestions, hasPermission: canSeeTemplates },
    { path: '/plantillas/:id/vista-previa', component: TemplatePreview, hasPermission: canSeeTemplates }
  ]
}
export default useTemplatesRoute
