import React from 'react'
import Container from '../../../atoms/Container'
import { headerInfo } from '../../../../configs/announcementConfig'
import AnnouncementForm from '../../../organisms/announcements/AnnouncementForm'
import { useHistory } from 'react-router-dom'
import useAnnouncement from '../../../../hooks/useAnnouncement'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'

const AnnouncementNew = () => {
  const { createAnnouncement } = useAnnouncement()
  const history = useHistory()

  const handleSave = async values => {
    try {
      await createAnnouncement(values, {
        onSuccess: () => {
          history.push('/avisos?action=announcement_created')
        }
      })
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <PageNavigation />
      <Container className="py-16">
        <AnnouncementForm headerInfo={headerInfo} initialValues={{}} onSubmit={handleSave} />
      </Container>
    </>
  )
}
export default AnnouncementNew
