import React from 'react'
import FormsFilters from './FormsFilters'
import InstitutionsFilters from './InstitutionsFilters'
import ReceivedRequestsFilters from './ReceivedRequestsFilters'
import { Form } from 'react-final-form'
import AutoSave from '../../../molecules/AutoSave'
import AccordionFilter from '../../../molecules/filters/AccordionFilter'
import PropTypes from 'prop-types'
import UsersFilters from './UsersFilters'
import AnswersFilters from './AnswersFilters'
import AppliedFilters from './AppliedFilters'

const FilterForm = ({ selectedIndicator, fetchFilteredData, initialFilters, accessRules }) => {
  if (!accessRules.includes(selectedIndicator)) {
    return null
  }

  const formTypeIndicators = handleSubmit => ({
    forms: <FormsFilters />,
    institutions: <InstitutionsFilters />,
    users: <UsersFilters />,
    answers: <AnswersFilters />,
    receivedRequests: <ReceivedRequestsFilters onChange={handleSubmit} />
  })

  return (
    <Form
      onSubmit={fetchFilteredData}
      initialValues={initialFilters}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <AutoSave debounce={1000} save={fetchFilteredData} />
          <AccordionFilter hasHeader keepOnClear={['published', 'date_from', 'date_to']}>
            <div className="md:pt-6 gap-10 mt-5 md:mt-0 w-full">
              {formTypeIndicators(fetchFilteredData)[selectedIndicator]}
              <AppliedFilters form={form} selectedIndicator={selectedIndicator} />
            </div>
          </AccordionFilter>
        </form>
      )}
    />
  )
}

FilterForm.propTypes = {
  selectedIndicator: PropTypes.string.isRequired,
  fetchFilteredData: PropTypes.func.isRequired,
  initialFilters: PropTypes.object.isRequired,
  accessRules: PropTypes.array.isRequired
}

export default FilterForm
