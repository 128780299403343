import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

function handleBeforeunload(e) {
  e.preventDefault()
  const confirmationMessage = ''
  e.returnValue = confirmationMessage
  return confirmationMessage
}

const BeforeunloadForm = ({ pristine, submitting, submitSucceeded }) => {
  useEffect(() => {
    if (pristine || submitting || submitSucceeded) {
      window.removeEventListener('beforeunload', handleBeforeunload)
    } else {
      window.addEventListener('beforeunload', handleBeforeunload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [pristine, submitting, submitSucceeded])

  return (
    <Prompt
      when={!pristine && !submitting && !submitSucceeded}
      message={() => 'Tu formulario tiene cambios sin guardar, ¿estás seguro que deseas salir?'}
    />
  )
}
export default BeforeunloadForm

BeforeunloadForm.propTypes = {
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitSucceeded: PropTypes.bool
}
